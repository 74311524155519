import { by639_1 as languages } from 'iso-language-codes';
import { round } from './math';
import { LOCALES } from '../constants/intl';

export const getNativeName = (localeCode) => {
  const language = languages[localeCode].nativeName.split(',')[0];
  return `${language.slice(0, 1).toUpperCase()}${language.slice(1)}`;
};

export const getAsCurrency = ({ currency }, locale, value) => new Intl
  .NumberFormat(locale, { style: 'currency', currency })
  .format(value);

export const getAsNumber = (locale, value) => new Intl
  .NumberFormat(locale, { style: 'decimal' })
  .format(round(value));

export default LOCALES.reduce((langpackLoader, locale) => ({
  ...langpackLoader,
  // eslint-disable-next-line prefer-template
  [locale]: () => import('../intl/' + locale + '.json'),
}), {});
