/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import sortBy from 'lodash/sortBy';
import { filesState } from '../../recoil/files';
import { loadingState } from '../../recoil/loading';
import { vehicleState, zoneState } from '../../recoil/metadata';
import {
  getUploadUrls, parseUploadedInvoices, uploadAllToS3, isDuplicate,
} from '../../utils/invoice';
import InvoiceUploadForm from './InvoiceUploadForm/InvoiceUploadForm';
import testData from '../../test-data';
import { parseInvoiceDateTimes } from '../../utils/datetime';

const InvoiceProcessor = () => {
  const [riderId, setRiderId] = useState('');
  const [files, setFiles] = useRecoilState(filesState);
  const setLoading = useSetRecoilState(loadingState);
  const zone = useRecoilValue(zoneState);
  const vehicle = useRecoilValue(vehicleState);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (zone.toLowerCase() === process.env.REACT_APP_TEST_DATA_KEY) {
      setFiles(testData.map(parseInvoiceDateTimes));
    }
  }, []);

  const parseFiles = async (fileList) => {
    setProgress(0);

    const { data: { riderId: newRiderId, invoices } } = await getUploadUrls(riderId,
      fileList.filter(({ type }) => type === 'application/pdf')
    );
    setProgress(20);

    await uploadAllToS3(fileList, invoices, setProgress, 20, 40);

    const results = await parseUploadedInvoices({
      invoices,
      riderId: newRiderId,
      zone,
      vehicle,
      setProgress,
      baseProgress: 60,
      progressAmount: 40,
    });

    return [newRiderId, results];
  };

  const onDrop = async (acceptedFiles) => {
    setLoading(true);

    const [newRiderId, invoices] = await parseFiles(acceptedFiles);

    const acceptedInvoices = invoices.reduce((fileList, invoice) => (
      isDuplicate(files, invoice.hash)
        ? fileList
        : [
          ...fileList,
          invoice,
        ]
    ), []);

    setRiderId(newRiderId);
    setFiles(sortBy([
      ...files,
      ...acceptedInvoices,
    ], 'start'));

    setLoading(false);
    setProgress(100);
  };

  return (
    <InvoiceUploadForm
      onDrop={onDrop}
      progress={progress}
    />
  );
};

export default InvoiceProcessor;
