import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import useLocalStorage from 'react-use-localstorage';
import { Spinner } from 'react-bootstrap';
import { IntlProvider } from 'react-intl';
import langpacks from '../utils/intl';
import { localeState } from '../recoil/locale';
import { DEFAULT_LOCALE, LOCALES } from '../constants/intl';

const Intl = ({ children }) => {
  const [locale, setLocale] = useRecoilState(localeState);
  const [langpack, setLangpack] = useState({});
  const [storedLocale, persistLocale] = useLocalStorage('langpack', DEFAULT_LOCALE);

  useEffect(() => {
    if (
      storedLocale !== DEFAULT_LOCALE
      && LOCALES.includes(storedLocale)
    ) {
      setLocale(storedLocale);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLangpack(await langpacks[locale]());
    })();
    persistLocale(locale);
  }, [locale]);

  return (
    <IntlProvider
      locale={locale}
      messages={langpack}
    >
      {
        Object.keys(langpack).length === 0
          ? (
            <div className="vh-100 d-flex align-items-center justify-content-center">
              <Spinner animation="border" />
            </div>
          )
          : children
      }
    </IntlProvider>
  );
};

Intl.propTypes = {
  children: PropTypes.node,
};

Intl.defaultProps = {
  children: null,
};

export default Intl;
