/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useDropzone } from 'react-dropzone';
import { useFathom } from 'fathom-react';
import { loadingState } from '../../../recoil/loading';
import { filesState } from '../../../recoil/files';
import { uploadingCompleteState } from '../../../recoil/uploadingComplete';
import ButtonWithDisabledHint from '../../ButtonWithDisabledHint';
import styles from './InvoiceUploadForm.module.scss';
import UploadHelp from './UploadHelp/UploadHelp';

const InvoiceUploadForm = ({ onDrop, progress }) => {
  const { goal } = useFathom();
  const isLoading = useRecoilValue(loadingState);
  const files = useRecoilValue(filesState);
  const setUploadingComplete = useSetRecoilState(uploadingCompleteState);

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop,
    accept: 'application/pdf',
  });

  const onComplete = () => {
    setUploadingComplete(true);
    goal(process.env.REACT_APP_FATHOM_UPLOAD_GOAL_ID, 0);
  };

  return (
    <div className="px-3 py-4 flex-grow-1 col-md-10 col-lg-8 offset-md-1 offset-lg-2 d-flex flex-column bg-secondary rounded">
      <h3 className={`${styles.title} m-0 text-center text-stratos`}>
        {isLoading && <FormattedMessage id="invoices.loading" />}
        {!isLoading && files.length > 0 && (
          <FormattedMessage
            id="invoices.subsequent"
            values={{ amount: files.length }}
          />
        )}
        {!isLoading && files.length === 0 && <FormattedMessage id="invoices.initial" />}
      </h3>
      <div className={`${styles.progress} my-3 w-100`}>
        {isLoading && (
          <div
            className={`${styles.bar} h-100`}
            style={{ width: `${progress}%` }}
          />
        )}
      </div>
      <UploadHelp />
      <div
        className={`${styles.drop} ${isDragAccept && styles.accept} my-3 p-3 flex-grow-1 d-flex flex-column justify-content-center align-items-center`}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p className="m-0">
          <span className="d-none d-sm-block">
            <FormattedMessage id="invoices.upload.desktop" />
          </span>
          <span className="d-sm-none">
            <FormattedMessage id="invoices.upload.mobile" />
          </span>
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <ButtonWithDisabledHint
          tooltipContent={<FormattedMessage id="invoices.confirm.hint" />}
          onClick={onComplete}
          disabled={files.length === 0}
          className={`mt-3 text-stratos font-size-stratos-body ${files.length > 0 && styles.pop}`}
        >
          <FormattedMessage id="invoices.confirm.text" />
        </ButtonWithDisabledHint>
      </div>
    </div>
  );
};

InvoiceUploadForm.propTypes = {
  onDrop: PropTypes.func.isRequired,
  progress: PropTypes.number,
};

InvoiceUploadForm.defaultProps = {
  progress: 0,
};

export default InvoiceUploadForm;
