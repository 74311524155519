import { Fragment, lazy, Suspense } from 'react';
import { Spinner } from 'react-bootstrap';
import Header from '../Header/Header';

const RiderStatsContainer = () => {
  const RiderStats = lazy(() => import('./RiderStats'));

  return (
    <Fragment>
      <div className="container">
        <Header />
      </div>
      <Suspense
        fallback={(
          <div className="flex-grow-1 d-flex align-items-center justify-content-center">
            <Spinner animation="border" />
          </div>
        )}
      >
        <RiderStats />
      </Suspense>
    </Fragment>
  );
};

export default RiderStatsContainer;
