import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import LanguageSelect from './LanguageSelect';
import styles from './Header.module.scss';

const Header = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="row my-3">
      <Helmet>
        <title>{formatMessage({ id: 'tabTitle' })}</title>
      </Helmet>
      <div className="col d-flex justify-content-between">
        <a href="https://ridersroovolt.com">
          <img
            className={styles.logo}
            src="https://cdn.iwgb.org.uk/bucket/CLB/Deliveroo/roovoltlinelogo.png"
            alt={formatMessage({ id: 'header.logoAltText' })}
          />
        </a>
        <div />
        <LanguageSelect />
      </div>
    </div>
  );
};

export default Header;
