import { s3 } from '../axios';

const upload = (
  url,
  contentType,
  data = {}
) => s3.put(url, data, {
  headers: {
    'x-amz-acl': 'private',
    'content-type': contentType,
  },
});

export default { upload };
