/* eslint-disable max-params */
import chunk from 'lodash/chunk';
import { DateTime } from 'luxon';
import { UUID_REGEX } from '../constants/invoice';
import { api, lambda } from '../axios';
import s3 from './s3';
import { parseInvoiceDateTimes } from './datetime';

const INVOICE_BATCH_SIZE = 3;

export const getRiderId = ({ name }) => (name.match(UUID_REGEX) || [])[0] || '';

export const getUploadUrls = (prevRiderId, fileList) => {
  let riderId = prevRiderId;
  if (riderId === '') {
    const riderIdFromFile = getRiderId(fileList[0]);
    if (riderIdFromFile === '') {
      riderId = `RI-${Date.now()}`;
    } else {
      riderId = `RI-${riderIdFromFile}`;
    }
  }
  return api.post('/getInvoiceUploadUrls', {
    riderId,
    invoices: fileList.map(({ name }) => name),
  });
};

export const uploadAllToS3 = async (
  fileList, invoices, setProgress, baseProgress, progressAmount
) => {
  let progress = baseProgress;
  // eslint-disable-next-line no-restricted-syntax
  for (const fileBatch of chunk(fileList, INVOICE_BATCH_SIZE)) {
    // eslint-disable-next-line no-await-in-loop
    await Promise.all(
      // eslint-disable-next-line no-loop-func
      fileBatch.map(async (file) => {
        await s3.upload(invoices[file.name].url, 'application/pdf', file);
        progress += progressAmount / fileList.length;
        setProgress(progress);
      })
    );
  }
};

export const parseUploadedInvoices = async ({
  invoices,
  riderId,
  zone,
  vehicle,
  setProgress,
  baseProgress,
  progressAmount,
}) => {
  let progress = baseProgress;
  const invoiceBatches = chunk(Object.values(invoices), INVOICE_BATCH_SIZE);
  let results = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const invoiceBatchGroup of chunk(invoiceBatches, INVOICE_BATCH_SIZE)) {
    results = [
      ...results,
      // eslint-disable-next-line no-await-in-loop
      ...await Promise.all(
        // eslint-disable-next-line no-loop-func
        invoiceBatchGroup.map(async (invoiceBatch) => {
          let response;
          try {
            response = await lambda.post('/parseInvoices', {
              riderId,
              zone,
              vehicle,
              invoiceIds: invoiceBatch.map(({ id }) => id),
              location: DateTime.local().zoneName,
            });
          } catch (error) {
            response = { data: [] };
          }

          progress += progressAmount / Math.ceil(
            Object.values(invoices).length / INVOICE_BATCH_SIZE
          );
          setProgress(progress);
          return response.data;
        })
      ),
    ];
  }

  return results.reduce((allResults, batch) => [
    ...allResults,
    ...batch.map((invoice) => parseInvoiceDateTimes(invoice)),
  ], []);
};

export const isDuplicate = (files, hash) => files.some(({ hash: needle }) => needle === hash);
