import PropTypes from 'prop-types';
import { COUNTRIES } from '../../constants/intl';
import Select from './Select';

const CountrySelect = ({ value, onChange }) => (
  <Select
    id="country"
    labelMessageId="metadata.country"
    hintMessageId="metadata.country.hint"
    value={value}
    onChange={onChange}
    options={COUNTRIES.map(({ name, code }) => ({
      value: code,
      labelId: `country.${name}`,
    }))}
    showPlaceholder={false}
  />
);

CountrySelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CountrySelect;
