import { Fragment } from 'react';
import { Accordion } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import UploadHelpSection from './UploadHelpSection';
import { arrayOf } from '../../../../utils/array';

const UploadHelp = () => (
  <Fragment>
    <p className="mb-1 text-center">
      <FormattedMessage id="invoices.help.fileType" />
    </p>
    <Accordion>
      <UploadHelpSection messageId="mobile">
        {arrayOf(3).map((_, i) => (
          // eslint-disable-next-line react/jsx-key
          <p>
            <FormattedMessage id={`invoices.help.mobile.${i + 1}`} />
          </p>
        ))}
      </UploadHelpSection>
      <UploadHelpSection messageId="privacy">
        <FormattedMessage id="invoices.help.privacy.body" />
      </UploadHelpSection>
    </Accordion>
  </Fragment>

);

export default UploadHelp;
