import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Tooltip from './Tooltip';

const ButtonWithDisabledHint = ({
  disabled, children, variant, onClick, tooltipContent, className,
}) => (
  <Tooltip
    disabled={!disabled}
    content={tooltipContent}
  >
    <span>
      <Button
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        className={className}
      >
        {children}
      </Button>
    </span>
  </Tooltip>
);

ButtonWithDisabledHint.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  tooltipContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
};

ButtonWithDisabledHint.defaultProps = {
  disabled: false,
  variant: 'primary',
  onClick: () => {},
  className: '',
};

export default ButtonWithDisabledHint;
