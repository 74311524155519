/* eslint-disable jsx-a11y/control-has-associated-label */
import { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useRecoilState, useSetRecoilState } from 'recoil';
import CountrySelect from './CountrySelect';
import VehicleSelect from './VehicleSelect';
import ButtonWithDisabledHint from '../ButtonWithDisabledHint';
import {
  countryState, isLondonState, vehicleState, zoneState,
} from '../../recoil/metadata';
import styles from './MetadataForm.module.scss';
import { COUNTRIES, DEFAULT_COUNTRY } from '../../constants/intl';

const UNITED_KINGDOM = 'unitedKingdom';

const MetadataForm = () => {
  const setGlobalCountry = useSetRecoilState(countryState);
  const setGlobalZone = useSetRecoilState(zoneState);
  const setGlobalVehicle = useSetRecoilState(vehicleState);
  const [isLondon, setLondon] = useRecoilState(isLondonState);
  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const [zone, setZone] = useState('');
  const [vehicle, setVehicle] = useState('');

  const onChangeCountry = ({ target: { value } }) => setCountry(value);

  const onChangeZone = ({ target: { value } }) => setZone(value);

  const onChangeVehicle = ({ target: { value } }) => setVehicle(value);

  const onChangeLondon = ({ target: { checked } }) => setLondon(checked);

  const onConfirm = () => {
    setGlobalCountry(COUNTRIES.find(({ name }) => name === country));
    setGlobalZone(zone.trim());
    setGlobalVehicle(vehicle);
    setLondon(country === UNITED_KINGDOM && isLondon);
  };

  const isComplete = useMemo(
    () => zone !== '' && vehicle !== '',
    [zone, vehicle]
  );

  return (
    <div className="row overflow-x-hidden">
      <div className="col-lg-8 offset-lg-2 text-stratos text-center">
        <h1 className={`${styles.title} text-uppercase`}>
          <FormattedMessage id="header.title" />
        </h1>
        <h3 className={styles.subtitle}>
          <FormattedMessage id="header.subtitle" />
        </h3>
      </div>
      <div className="col-md-8 col-xl-6 offset-md-2 offset-xl-3 py-4 d-flex flex-column">
        <div className="px-4 py-4 d-flex flex-column bg-secondary rounded">
          <div className="mb-2">
            <CountrySelect
              value={country}
              onChange={onChangeCountry}
            />
          </div>
          <div className="mb-2">
            <Form.Group
              controlId="zone"
              className="w-100"
            >
              <Form.Label>
                <FormattedMessage id="metadata.zone" />
              </Form.Label>
              <Form.Control
                value={zone}
                onChange={onChangeZone}
              />
            </Form.Group>
          </div>
          {country === UNITED_KINGDOM && (
            <div>
              <Form.Group
                controlId="london"
                className="d-flex justify-content-between"
              >
                <Form.Label>
                  <FormattedMessage id="metadata.london" />
                </Form.Label>
                <Form.Check
                  type="switch"
                  value={isLondon}
                  onChange={onChangeLondon}
                />
              </Form.Group>
            </div>
          )}
          <div className="mb-2">
            <VehicleSelect
              value={vehicle}
              onChange={onChangeVehicle}
            />
          </div>
          <div className="d-flex flex-column align-items-center">
            <ButtonWithDisabledHint
              onClick={onConfirm}
              disabled={!isComplete}
              tooltipContent={<FormattedMessage id="metadata.continue.hint" />}
              className="text-stratos font-size-stratos-body mb-3"
            >
              <FormattedMessage id="metadata.continue" />
            </ButtonWithDisabledHint>
            <small className="text-center">
              <FormattedMessage
                id="metadata.privacy"
                values={{
                  privacyPolicy: (
                    <a href="https://iwgb.org.uk/page/privacy">
                      <FormattedMessage id="metadata.privacyPolicy" />
                    </a>
                  ),
                }}
              />
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetadataForm;
