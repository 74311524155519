export const DEFAULT_LOCALE = 'en';

export const LOCALES = ['en', 'pt'];

export const COUNTRIES = [
  {
    name: 'unitedKingdom',
    currency: 'GBP',
  },
];

export const DEFAULT_COUNTRY = COUNTRIES[0].name;
