import { FormattedMessage } from 'react-intl';
import styles from './Footer.module.scss';

const Footer = () => (
  <div className="row my-5 flex-column justify-content-center align-items-center">
    <div className="mb-1">
      <FormattedMessage
        id="footer"
        values={{
          iwgb: (
            <a
              href="https://iwgb.org.uk"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="IWGB"
                src="https://cdn.iwgb.org.uk/assets/wordmark-white.png"
                className={`${styles.wordmark} mb-2 mx-2`}
              />
            </a>
          ),
        }}
      />
    </div>
    <small className={styles.muted}>
      <FormattedMessage id="footer.builtBy" />
    </small>
    <small className={styles.muted}>
      <ul className={`${styles.bulletLinks} d-flex my-2 p-0`}>
        <li className="mr-1">
          <a
            href="https://iwgb.org.uk/page/privacy"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id="footer.privacy" />
          </a>
        </li>
        <li className="mr-1">
          <a href="https://iwgb.org.uk/page/data-protection">
            <FormattedMessage id="footer.dataProtection" />
          </a>
        </li>
        <li className="mr-1">
          <a href="https://iwgb.org.uk/page/cookies">
            <FormattedMessage id="footer.cookies" />
          </a>
        </li>
      </ul>
    </small>
  </div>
);

export default Footer;
