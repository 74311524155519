import { atom, selector } from 'recoil';
import { COUNTRIES, DEFAULT_COUNTRY } from '../constants/intl';

export const countryState = atom({
  key: 'country',
  default: COUNTRIES.find(({ name }) => name === DEFAULT_COUNTRY),
});

export const zoneState = atom({
  key: 'zone',
  default: '',
});

export const vehicleState = atom({
  key: 'vehicle',
  default: '',
});

export const isLondonState = atom({
  key: 'london',
  default: false,
});

export const metadataCompleteState = selector({
  key: 'metadataComplete',
  get: ({ get }) => get(zoneState) && get(vehicleState),
});
