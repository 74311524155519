import { DateTime, Interval } from 'luxon';
import { round } from './math';

export const getIntervalString = (start, end) => {
  if (start.hasSame(end, 'day')) {
    return start.toLocaleString(DateTime.DATE_MED);
  }
  if (start.hasSame(end, 'month')) {
    return `${start.toFormat('d')}-${end.toLocaleString(DateTime.DATE_MED)}`;
  }
  if (start.hasSame(end, 'year')) {
    return `${start.toFormat('d LLL')} - ${end.toLocaleString(DateTime.DATE_MED)}`;
  }
  return `${start.toLocaleString(DateTime.DATE_MED)} - ${end.toLocaleString(DateTime.DATE_MED)}`;
};

export const parseInvoiceDateTimes = (invoice) => ({
  ...invoice,
  start: DateTime.fromISO(invoice.start),
  end: DateTime.fromISO(invoice.end),
  shifts: invoice.shifts.map((shift) => ({
    ...shift,
    start: DateTime.fromISO(shift.start),
    end: DateTime.fromISO(shift.end),
  })),
});

export const daysFromInterval = (interval) => {
  let day = interval.start.startOf('day');
  let period = [];
  while (day < interval.end) {
    period = [...period, day];
    day = day.plus({ days: 1 });
  }
  return period;
};

export const getShiftHours = (start, end) => round(Interval.fromDateTimes(start, end).length('hours'));
