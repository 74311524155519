import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { RecoilRoot } from 'recoil';
import Fathom from 'fathom-react';
import InvoiceTool from './components/InvoiceTool';
import { LOCALES } from './constants/intl';
import 'tippy.js/dist/tippy.css';
import './App.scss';

const App = () => (
  <Fragment>
    <Helmet
      defaultTitle="Deliveroo Invoice Tool · Riders Roovolt"
      titleTemplate="%s · Riders Roovolt"
    >
      <script
        src={`https://polyfill.io/v3/polyfill.min.js?${new URLSearchParams({
          features: LOCALES
            .map((locale) => `Intl.~locale.${locale}`)
            .join(','),
        })}`}
      />
      {[32, 64, 96, 128].map((size) => (
        <link
          key={size}
          rel="icon"
          href={`https://cdn.iwgb.org.uk/assets/favicon-${size}.png`}
          sizes={`${size}x${size}`}
        />
      ))}
    </Helmet>
    <RecoilRoot>
      <Fathom
        siteId={process.env.REACT_APP_FATHOM_SITE_ID}
        customDomain={process.env.REACT_APP_FATHOM_DOMAIN}
      >
        <InvoiceTool />
      </Fathom>
    </RecoilRoot>
  </Fragment>
);

export default App;
