import { useRecoilValue } from 'recoil';
import SlideDown from 'react-slidedown';
import Header from './Header/Header';
import InvoiceProcessor from './InvoiceProcessor/InvoiceProcessor';
import MetadataForm from './MetadataForm/MetadataForm';
import Footer from './Footer/Footer';
import RiderStatsContainer from './RiderStats/RiderStatsContainer';
import { metadataCompleteState } from '../recoil/metadata';
import { uploadingCompleteState } from '../recoil/uploadingComplete';
import Intl from './Intl';
import 'react-slidedown/lib/slidedown.css';

const InvoiceTool = () => {
  const isMetadataComplete = useRecoilValue(metadataCompleteState);
  const isUploadingComplete = useRecoilValue(uploadingCompleteState);

  return (
    <Intl>
      {!isUploadingComplete && (
        <div className="container d-flex flex-column min-vh-100">
          <Header />
          <div className="flex-grow-1 d-flex flex-column">
            <SlideDown transitionOnAppear={false}>
              {!isMetadataComplete && <MetadataForm />}
            </SlideDown>
            {isMetadataComplete && (
              <SlideDown
                className="flex-grow-1 d-flex flex-column"
                transitionOnAppear={false}
              >
                <InvoiceProcessor />
              </SlideDown>
            )}
          </div>
          <Footer />
        </div>
      )}
      {isUploadingComplete && (
        <div className="d-flex flex-column min-vh-100">
          <RiderStatsContainer />
        </div>
      )}
    </Intl>
  );
};

export default InvoiceTool;
