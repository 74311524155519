import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import LabelWithHint from './LabelWithHint';

const Select = ({
  id, labelMessageId, hintMessageId, value, onChange, options, showPlaceholder,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Form.Group
      controlId={id}
      className="w-100"
    >
      <LabelWithHint
        labelMessageId={labelMessageId}
        hintMessageId={hintMessageId}
      />
      <Form.Control
        as="select"
        className="custom-select"
        value={value}
        onChange={onChange}
      >
        {showPlaceholder && (
          <option value="">
            {formatMessage({ id: 'metadata.dropdownPlaceholder' })}
          </option>
        )}
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
          >
            {formatMessage({ id: option.labelId })}
          </option>
        ))}
      </Form.Control>
    </Form.Group>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  labelMessageId: PropTypes.string.isRequired,
  hintMessageId: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    labelId: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  showPlaceholder: PropTypes.bool,
};

Select.defaultProps = {
  hintMessageId: '',
  options: [],
  showPlaceholder: true,
};

export default Select;
