import { Fragment } from 'react';
import { useRecoilState } from 'recoil';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { localeState } from '../../recoil/locale';
import messages, { getNativeName } from '../../utils/intl';

const LanguageSelect = () => {
  const [currentLocale, setLocale] = useRecoilState(localeState);

  const onSelectLocale = (locale) => () => setLocale(locale);

  const onDropdownChange = ({ target: { value } }) => setLocale(value);

  const locales = Object.keys(messages);

  return (
    <Fragment>
      <div className="d-none d-md-flex align-items-center">
        {
          locales.map((locale) => (
            <Button
              key={locale}
              variant="link"
              onClick={onSelectLocale(locale)}
              className={locale === currentLocale && 'font-weight-bold'}
            >
              {getNativeName(locale)}
            </Button>
          ))
        }
      </div>
      <div className="d-flex d-md-none align-items-center">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className="sr-only"
          htmlFor="locale-picker"
        >
          <FormattedMessage id="header.language" />
        </label>
        <select
          id="locale-picker"
          className="custom-select"
          value={currentLocale}
          onChange={onDropdownChange}
        >
          {
            locales.map((locale) => (
              <option
                key={locale}
                value={locale}
              >
                {getNativeName(locale)}
              </option>
            ))
          }
        </select>
      </div>
    </Fragment>

  );
};

export default LanguageSelect;
