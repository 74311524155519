/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';

const Tooltip = ({ children, ...rest }) => (
  <Tippy
    arrow={true}
    duration={200}
    {...rest}
  >
    {children}
  </Tippy>
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tooltip;
