import { useContext } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionContext, Card } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import styles from './UploadHelpSection.module.scss';

const UploadHelpSection = ({ messageId, children }) => {
  const currentMessageId = useContext(AccordionContext);

  const isOpen = currentMessageId === messageId;

  return (
    <Card className={`${styles.card} border-0`}>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={messageId}
        className={`${styles.header} border-0 d-flex justify-content-center align-items-center`}
      >
        <div className={`${styles.icon} ${isOpen && styles.open} d-flex justify-content-center mr-2`}>
          <Icon icon={faAngleRight} />
        </div>
        <span className={styles.message}>
          <FormattedMessage id={`invoices.help.${messageId}.label`} />
        </span>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={messageId}>
        <Card.Body className="py-1 text-center">
          {children}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

UploadHelpSection.propTypes = {
  messageId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default UploadHelpSection;
