import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../Tooltip';

const LabelWithHint = ({ labelMessageId, hintMessageId }) => (
  <Form.Label className="d-flex justify-content-between align-items-center">
    <FormattedMessage id={labelMessageId} />
    {hintMessageId && (
      <Tooltip
        placement="left"
        content={(
          <div className="text-center">
            <FormattedMessage id={hintMessageId} />
          </div>
        )}
      >
        <div className="px-1">
          <Icon icon={faQuestionCircle} />
        </div>
      </Tooltip>
    )}
  </Form.Label>
);

LabelWithHint.propTypes = {
  labelMessageId: PropTypes.string.isRequired,
  hintMessageId: PropTypes.string,
};

LabelWithHint.defaultProps = {
  hintMessageId: '',
};

export default LabelWithHint;
