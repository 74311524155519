import PropTypes from 'prop-types';
import VEHICLES from '../../constants/vehicles';
import Select from './Select';

const VehicleSelect = ({ value, onChange }) => (
  <Select
    id="vehicle"
    labelMessageId="metadata.vehicle"
    value={value}
    onChange={onChange}
    options={VEHICLES.map((vehicleId) => ({
      value: vehicleId,
      labelId: `vehicle.${vehicleId}`,
    }))}
  />
);

VehicleSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VehicleSelect;
