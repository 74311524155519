import axios from 'axios';
import retry from 'axios-retry';

const retryConfig = {
  retryCondition: () => true,
  retryDelay: retry.exponentialDelay,
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_ROOVOLT_API_BASE_URL,
});

export const lambda = axios.create({
  baseURL: process.env.REACT_APP_LAMBDA_BASE_URL,
});

export const s3 = axios.create();

retry(s3, retryConfig);
